body {
  font-family: Arial, Helvetica, sans-serif;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

p {
  margin-bottom: 0;
}

.jumbotron {
  margin: 0 !important;
  padding: 0 !important;
}

.post-detail-wrapper p {
  font-size: 16px;
  margin-bottom: 24px;
}

.post-detail-wrapper h2 {
  font-size: 16px;
  font-weight: bold;
}